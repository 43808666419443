import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Responsive.css";
import Dat from "./pages/datenschutz";
import Imp from "./pages/impressum";
import ScrollToTop from "./utils/ScrollToTop";
const Home = lazy(() => import('./pages/Home'));

const LoadingFallback = () => <div>Loading...</div>;

function App() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <BrowserRouter basename="">
        <ScrollToTop />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/impressum" Component={Imp} />
          <Route path="/datenschutz" Component={Dat} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
