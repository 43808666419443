import React from "react";
import { Link } from "react-router-dom";

function Dat() {
  return (
    <div className="container italic">
      <Link to="/" className="right-text">
        zurück
      </Link>{" "}
      <h1>Datenschutzerklärung</h1>
      <div className="mBlock15 mt25">
        <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
          Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, 
          werden diese nur zum firmeninternen Gebrauch wie beispielsweise Kontaktaufnahme verwendet. Die Daten werden nicht an Dritte weitergegeben. 
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht 
          veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien 
          wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten 
          Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor. Arten der verarbeiteten Daten:</p>
          <br />
        <p> – Bestandsdaten (z.B., Namen, Adressen).</p>
        <p> – Kontaktdaten (z.B., E-Mail, Telefonnummern).</p>
        <p> – Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</p>
        <p> – Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</p>
        <p> – Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>ZWECK DER VERARBEITUNG</h3>
        <p> – Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.</p>
        <p> – Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</p>
        <p> – Sicherheitsmaßnahmen.</p>
        <p> – Reichweitenmessung/Marketing</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>HINWEIS ZUR VERANTWORTLICHEN STELLE</h3>
        <br />
        <p>VIERVORNEUN GmbH</p>
        <p>Friedberger Landstr. 94, 60316 Frankfurt am Main</p>
        <p>Vertreten durch: Quynh Anh Nguyen & Thi Bich Ngoc Nguyen</p>
        <p>E-Mail: info@viervorneun.com</p>
        <br />
        <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder <br />gemeinsam mit anderen über die Zwecke und Mittel 
          der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>SSL- BZW. TLS-VERSCHLÜSSELUNG</h3>
        <br />
        <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, 
          die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, 
          dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. 
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>SERVER-LOG-DATEIEN</h3>
        <br />
        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
          <br />
        <p> – Browsertyp und Browserversion</p>
        <p> – verwendetes Betriebssystem</p>
        <p> – Referrer URL</p>
        <p> – Hostname des zugreifenden Rechners</p>
        <p> – Uhrzeit der Serveranfrage</p>
        <p> – IP-Adresse</p>
        <br />
        <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>WIDERRUFSRECHT</h3>
        <br />
        <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>WIDERSPRUCHSRECHT</h3>
        <br />
        <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>NUTZUNG VON COOKIES</h3>
        <br />
        <p>Die Internetseiten verwenden teilweise so genannte Cookies. Diese dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.</p>
      </div>
      <div className="mBlock15 mt25">
        <h3>GOOGLE FONTS</h3>
        <br />
        <p>Unsere Website verwendet zur einheitlichen Darstellung von Schriftarten sogenannte Google Fonts, die vom Anbieter Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, bereitgestellt werden. Die Google Fonts sind lokal installiert, sodass keine Verbindung zu den Servern von Google hergestellt wird.

Weitere Informationen zu Google Fonts finden Sie unter Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.</p>
      </div>
    </div>
  );
}

export default Dat;
